import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPricings(ctx, data) {
      return useJwt.getPricings(data).then(response => response);
    },
    createPricing(ctx, data) {
      return useJwt.createPricing(data).then(response => response);
    },
    updatePricing(ctx, data) {
      return useJwt.updatePricing(data).then(response => response);
    },
    deletePricing(ctx, data) {
      return useJwt.deletePricing(data).then(response => response);
    },
  },
};
