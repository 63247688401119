<template>
  <b-modal
    id="modal-pricing"
    ref="refModal"
    title="Gói khám"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    scrollable
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <!-- <avatar-view align="center" /> -->
            <image-view
              v-model="itemLocal.image"
              label="Ảnh đại diện"
              align="center"
              :src="itemLocal.image"
              :width="200"
              :height="200"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên gói <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="code"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Mã gói <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="code"
                  v-model="itemLocal.code"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <b-form-group>
              <template v-slot:label>
                Thứ tự hiển thị <span class="text-danger">(*)</span>
              </template>
              <spin-button
                v-model="itemLocal.order"
                :min="1"
                :step="1"
                :default-value="itemLocal.order"
              />
            </b-form-group>

          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Giá gói"
              rules="required"
            >
              <number-input
                v-model="itemLocal.pricing"
                required
                label="Giá gói"
                :default-value="itemLocal.pricing"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Ưu đãi"
              rules=""
            >

              <select-discount
                v-model="itemLocal.promotion"
                :default-value="itemLocal.promotion"
                label="Ưu đãi"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col cols="12">
            <b-form-group label="Mô tả">
              <b-form-textarea
                id="description"
                v-model="itemLocal.description"
                placeholder="Mô tả"
                rows="3"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="demo-inline-spacing">

              <b-form-radio
                v-model="itemLocal.type"
                name="type"
                value="time"
                class="custom-control-primary"
              >
                Tính theo khoảng thời gian
              </b-form-radio>

              <b-form-radio
                v-model="itemLocal.type"
                name="type"
                value="transaction"
                class="custom-control-primary"
              >
                Tính theo số lần khám
              </b-form-radio>
            </div>

          </b-col>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="Ưu đãi"
              rules="required|minValue:1"
            >

              <b-form-group
                label="Thời gian của gói"
              >
                <b-input-group>

                  <b-form-input
                    v-model="itemLocal.expire_time"
                    placeholder="Vui lòng nhập"
                    :state="getValidationState(validationContext)"
                  />
                  <b-input-group-append>
                    <b-dropdown
                      :text="itemLocal.time_type === 'month' ? 'Tháng' : 'Năm'"
                      variant="outline-primary"
                    >
                      <b-dropdown-item @click="itemLocal.time_type = 'month'">
                        Tháng
                      </b-dropdown-item>
                      <b-dropdown-item @click="itemLocal.time_type = 'year'">
                        Năm
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-input-group-append>

                </b-input-group>
                <small class="text-danger">{{ validationContext.errors[0] }}</small>
              </b-form-group>
            </validation-provider>

          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Số lần khám"
              rules="required"
            >
              <number-input
                v-model="itemLocal.expire_transaction"
                :disabled="itemLocal.type !== 'transaction'"
                label="Số lần khám"
                :default-value="itemLocal.expire_transaction"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.status"
                name="check-button"
                switch
                inline
              >
                Hiển thị?
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit -->
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BFormTextarea,
  BFormCheckbox,
  VBTooltip,
  BFormRadio,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BInputGroup,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, minValue } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import ImageView from '@/views/components/ImageView.vue';
import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import SpinButton from '@/views/components/SpinButton.vue';
import NumberInput from '@/views/components/NumberInput.vue';
import SelectDiscount from '@/views/components/SelectDiscount.vue';
import usePricingModal from './usePricingModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormCheckbox,
    ImageView,
    SpinButton,
    NumberInput,
    BFormRadio,
    SelectDiscount,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      minValue,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = usePricingModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
